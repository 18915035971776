
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import { mapActions } from 'vuex'
  import GCostField from '@/components/core/input/GCostField.vue'
  import GAlert from '@/components/core/alert/GAlert.vue'

@Component({
  components: { GAlert, GCostField, FieldTitle, RatingCell },
  methods: {
    ...mapActions('resources/form', ['findLeadActivity']),
  },
})
  export default class inspectionQualification extends BasePanel {
  @Prop({ type: String, default: null }) type: string
  @Prop({ type: Boolean, default: false }) isOver: string
  @Prop({ type: Boolean, default: false }) hidden: boolean
  @Prop({ type: Object, default: () => null }) config: Record<string, any>
  rating = 0
  message = ''
  agree = null
  cost = null
  comment = ''
  errorMessageCost = ''
  errorMessageComment = ''
  alert = {
    open: false,
    title: 'Debe calificar la inspección para continuar',
  }

  findLeadActivity!: (payload: number) => Promise<any>

  get isSupervisor () {
    return this.type === 'supervisor'
  }

  get isRequiredFields () {
    return !this.inspection?.metadata?.validated
  }

  get ratingLength () {
    const { inspection, config } = this

    if (inspection?.metadata?.progress < 100) {
      return config?.max
    }

    return 5
  }

  get isValidated () {
    const { inspection } = this

    return inspection?.metadata?.validated
  }

  @Watch('sendQualification', { immediate: true })
  async onSendQualificationChange (val) {
    if (!val) return
    const { type, rating, comment, cost, isRequiredFields, isOver } = this

    if (isRequiredFields && type !== 'inspector') {
      this.errorMessageCost = !cost && !isOver ? 'Este campo es requerido' : ''
      this.errorMessageComment = !comment ? 'Este campo es requerido' : ''
      if ((!cost && !isOver) || !comment) {
        this.$emit('set-qualification')
        return
      }
    }
    if (!rating) {
      this.alert.open = true
      this.rating = null
      this.$emit('set-qualification')
      return
    }

    if (type === 'inspector') {
      await this.putInspectorQualification(rating)
      const { inspection } = this

      const idActivity = await this.findLeadActivity(inspection.deal.lead.id)

      if (idActivity) {
        const close = await this.fetchData({
          query: { name: 'find', model: 'ProcessStatus' },
          filter: { _and: [{ process: { table_name: { _eq: 'lead_activity' } } }, { status: { name: { _eq: 'closed' } } }] },
        })
        await this.pushData({
          model: 'LeadActivity',
          fields: { id: idActivity, activity_result: true, id_process_status: close?.[0]?.id },
        })
      }
    } else {
      await this.putSupervisorQualification(rating, this.cost, this.comment, this.isOver)
    }
    await this.close()
  }

  @Watch('agree', { immediate: true })
  onAgreeChange (val) {
    this.$emit('allow-send', val)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionRatingChange (val) {
    const { type } = this
    this.rating = type === 'inspector' ? val?.inspectorQualification : val?.supervisorQualification
    if (val?.inspectorQualification) {
      this.agree = true
    }

    this.comment = this.inspection?.metadata?.comment
    this.cost = this.inspection?.metadata?.authorizedAmount
  }

  @Watch('rating', { immediate: true, deep: true })
  onConfigChange (val) {
    if (!val) {
      this.message = null
      return
    }

    if (this.config?.max && val <= this.config?.max && this.isOver) {
      this.message = `Con está calificación la inspección queda en "No califica", ¿Esta seguro que quiere enviarla?`
    } else {
      const messageInspector = `Con está calificación la inspección quedara "por validar", ¿Esta seguro que quiere enviarla?`
      const messageSupervisor = this.isRequiredFields ? `Con está calificación se generará la negociación, ¿Esta seguro que quiere enviarla?` : `Con está calificación la inspección queda "Validada" y se generará la negociación, ¿Esta seguro que quiere enviarla?`
      this.message = this.type === 'inspector' ? messageInspector : messageSupervisor
    }
  }
  }
